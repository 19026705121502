import { FunctionComponent, useContext, useEffect, useState } from "react";
import { ChapterFunctionary, Member, Person } from "../types/apiTypes";
import { DataContext } from "./AppRouter";
import MoreButton from "./MoreButton";
import PersonActions from "./PersonActions";
import PersonBoxes from "./PersonBoxes";
import useFetchCommittee from "./hooks/useFetchCommittee";
import useFetchMember from "./hooks/useFetchMember";
import Loading from "./ui/Loading";

interface Props {
  isCommittee?: boolean;
}

const filterPersonByQuery = (
  personList: Person[] | undefined,
  inputValue: string,
) =>
  personList?.filter(
    (person) =>
      person.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
      person.lastName?.toLowerCase().includes(inputValue.toLowerCase()) ||
      person.companyName?.toLowerCase().includes(inputValue.toLowerCase()),
  );

const isChapterFunctionary = (
  person: ChapterFunctionary | Member,
): person is ChapterFunctionary =>
  (person as ChapterFunctionary).person !== undefined;

const getTypedPersons = (persons: ChapterFunctionary[] | Member[]): Person[] =>
  persons.map((item) => {
    const isFunctionary = isChapterFunctionary(item);

    return {
      name: isFunctionary
        ? item.person.firstName
        : item.legalRepresentative.firstName,
      lastName: isFunctionary
        ? item.person.lastName
        : item.legalRepresentative.lastName,
      email: isFunctionary ? item.person.email : item.email,
      phone: isFunctionary ? undefined : item.phone,
      mobile: isFunctionary ? item.person.mobile : undefined,
      street: isFunctionary ? item.person.street : item.street,
      zip: isFunctionary ? item.person.zip : item.zip,
      city: isFunctionary ? item.person.city : item.city,
      companyName: item.company,
      id: isFunctionary ? item.id : null,
      picture: isFunctionary ? item.person.picture : null,
      roles: isFunctionary ? item.roles : null,
      website: isFunctionary ? undefined : item.website,
    };
  });

const PersonList: FunctionComponent<Props> = ({ isCommittee = false }) => {
  const multiplier = 8;
  const { chapter } = useContext(DataContext);
  const fetchedPersons = isCommittee
    ? useFetchCommittee(chapter)
    : useFetchMember(chapter);

  const [inputValue, setInputValue] = useState("");
  const [originalPersons, setOriginalPersons] = useState<Person[]>();
  const [persons, setPersons] = useState<Person[]>();
  const [pagination, setPagination] = useState(0);

  useEffect(() => {
    setPersons(undefined);
  }, [chapter]);

  useEffect(() => {
    if (!fetchedPersons) return;

    const typedPersons = getTypedPersons(fetchedPersons);

    setTimeout(() => {
      setPagination(0);
      setOriginalPersons(typedPersons);
      setPersons(typedPersons);
    }, 350);
  }, [fetchedPersons]);

  useEffect(() => {
    setPersons(filterPersonByQuery(originalPersons, inputValue));
  }, [inputValue]);

  return (
    <>
      {!persons ? (
        <Loading />
      ) : (
        <>
          <PersonActions
            items={persons}
            inputValue={inputValue}
            setInputValue={setInputValue}
            isCommittee={isCommittee}
          />
          {persons.length ? (
            <PersonBoxes
              items={
                persons.length < multiplier
                  ? persons
                  : persons.slice(0, pagination + multiplier)
              }
              showImage={false}
            />
          ) : (
            <div>Keine Ergebnisse</div>
          )}
          <MoreButton
            isVisible={
              persons.length > multiplier &&
              pagination + multiplier < persons.length
            }
            onClick={() => setPagination(pagination + multiplier)}
          />
        </>
      )}
    </>
  );
};

export default PersonList;
