import classNames from "classnames";
import Markdown from "markdown-to-jsx";
import { FunctionComponent, RefObject, useEffect, useState } from "react";
import { cmsUrl } from "../http/cmsApi";
import SVGOpen from "../icons/Open.svg?react";
import { TextSections } from "../types/types";
import styles from "./TabPanel.module.css";
import Button from "./ui/Button";
import Icon from "./ui/Icon";
import Link from "./ui/Link";

interface Props {
  content: TextSections;
  tabPanelRef: RefObject<HTMLDivElement>;
}

const TabPanel: FunctionComponent<Props> = ({ content, tabPanelRef }) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    const tabHeight = tabPanelRef.current?.clientHeight;

    tabHeight && setExpand(tabHeight < 700);
  }, [content]);

  return (
    <div ref={tabPanelRef}>
      <h3 className={styles.title}>
        {content.section} {content.title}
      </h3>
      <div
        className={classNames(styles["tab-content"], {
          [styles["tab-expand"]]: expand,
        })}
      >
        <Markdown>{content.text}</Markdown>
        {content.pdf && (
          <div className={styles["pdf-container"]}>
            <Link
              newTab={true}
              url={`${cmsUrl}${location.pathname}/${content.pdf}`}
            >
              <Icon glyph={SVGOpen} /> Als PDF öffnen
            </Link>
          </div>
        )}
      </div>
      {!expand && (
        <div className={styles["more-container"]}>
          <Button buttonProps={{ onClick: () => setExpand(true) }}>
            Mehr lesen
          </Button>
        </div>
      )}
    </div>
  );
};

export default TabPanel;
