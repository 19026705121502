import classNames from "classnames";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { getOrganizationChart } from "../../http/chapterApi";
import { Committee, OrganizationType } from "../../types/apiTypes";
import { LastViewedObject } from "../../types/types";
import Page from "../Page";
import useFetchPage from "../hooks/useFetchPage";
import styles from "./OrganizationChart.module.css";

interface GroupedDistrict {
  district: { id: number; name: string };
  items: Committee[];
}

interface Props {
  setLastViewed: (value: LastViewedObject) => void;
}

const groupByDistrict = (data: Committee[]) => {
  const groupedData = data.reduce<Record<string, GroupedDistrict>>(
    (prev, curr) => ({
      ...prev,
      [curr.district.id]: prev[curr.district.id]
        ? {
            ...prev[curr.district.id],
            items: [...(prev[curr.district.id]?.items ?? []), curr],
          }
        : { district: curr.district, items: [curr] },
    }),
    {},
  );

  const ids = [7296, 6001, 7883];
  const sortedData = Object.values(groupedData).map((data) => {
    const sortedItems = data.items.sort((a) => (ids.includes(a.id) ? -1 : 1));

    return { ...data, items: sortedItems };
  });

  return Object.values(sortedData).sort((a, b) =>
    a.district.name < b.district.name ? -1 : 1,
  );
};

const Organigram: FunctionComponent<Props> = ({ setLastViewed }) => {
  const page = useFetchPage(setLastViewed);
  const [organization, setOrganization] = useState<OrganizationType>();

  useEffect(() => {
    (async () => setOrganization(await getOrganizationChart()))();
  }, []);

  return (
    <>
      {page && (
        <Page title={page.frontmatter.title} description={page.content}>
          {organization && (
            <div className={styles.organigram}>
              <div className={styles.section}>
                <div className={classNames(styles.row, styles.rowThree)}>
                  <div className={styles.center}>
                    <p className={styles.header}>Präsident/in</p>
                    <ul className={styles.row}>
                      <li className={styles.item}>
                        {organization.president?.person.firstName}{" "}
                        {organization.president?.person.lastName}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <p className={styles.header}>Landesausschuss</p>
                <ul className={classNames(styles.row, styles.rowThree)}>
                  {organization.countryCommittee.map((item) => (
                    <li className={styles.item} key={item.person.id}>
                      {item.person.firstName} {item.person.lastName}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.section}>
                <p className={styles.header}>Bezirk</p>
                <dl className={classNames(styles.row, styles.rowFour)}>
                  {groupByDistrict(organization.districtCommittee).map(
                    (district) => (
                      <Fragment key={district.district.id}>
                        {district.items.map((item) => (
                          <div className={styles.items} key={item.person.id}>
                            <dt
                              className={classNames(styles.item, styles.dark)}
                            >
                              {item.district.name}
                            </dt>
                            <dd className={styles.item}>
                              {item.person.firstName} {item.person.lastName}
                            </dd>
                          </div>
                        ))}
                      </Fragment>
                    ),
                  )}
                </dl>
              </div>
              <div className={styles.section}>
                <p className={styles.header}>Gebiet</p>
                <div className={classNames(styles.row, styles.rowFour)}>
                  {groupByDistrict(organization.areaCommittee).map((area) => (
                    <div key={area.district.id}>
                      {area.items.map((item) => (
                        <div className={styles.items} key={item.person.id}>
                          <dt className={classNames(styles.item, styles.dark)}>
                            {item.area.name}
                          </dt>
                          <dd className={styles.item}>
                            {item.person.firstName} {item.person.lastName}
                          </dd>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.section}>
                <div className={classNames(styles.row, styles.rowThree)}>
                  <p className={classNames(styles.header, styles.center)}>
                    {organization.chapters} Ortsgruppen
                  </p>
                </div>
                <div className={styles.row}>
                  <p className={styles.item}>
                    ORTSOBMANN/-FRAU, ORTSAUSSCHUSS, ORTSVERSAMMLUNG
                  </p>
                </div>
              </div>
              <div className={styles.section}>
                <div className={classNames(styles.row, styles.rowThree)}>
                  <p className={classNames(styles.header, styles.center)}>
                    {organization.members} Mitglieder
                  </p>
                </div>
              </div>
            </div>
          )}
        </Page>
      )}
    </>
  );
};

export default Organigram;
