import { navItemsGroup } from "./components/Menu";
import { CmsPageType, LastViewedObject } from "./types/types";

const getSavedPages = (): LastViewedObject => {
  const lastViewedPagesJson =
    localStorage.getItem("lastViewedPages") ?? undefined;
  return lastViewedPagesJson ? JSON.parse(lastViewedPagesJson) : {};
};

const removeItem = (pages: LastViewedObject, slug: string) => {
  const { [slug]: omit, ...res } = pages;

  return res;
};

const handleLastViewedDelete = (pages: LastViewedObject) => {
  const keys = Object.keys(pages);
  if (keys.length < 6 || !keys[0]) return pages;

  return removeItem(pages, keys[0]);
};

const getParentTitle = (slug: string) => {
  const parent = navItemsGroup
    .filter((menu) => menu.items.filter((item) => item.to === slug).length)
    .shift();

  const staticParent: Record<string, string> = {
    "/kontakte": "Kontakte",
    "/newsletter": "Newsletter",
    "/terminkalender": "Terminkalender",
    "/organigramm": "Organigramm",
  };

  if (!parent?.text) return staticParent[slug];

  return parent.text;
};

const addCurrent = (lastViewedPages: LastViewedObject, page: CmsPageType) => {
  const slug = location.pathname;
  const removedDuplicate = removeItem(lastViewedPages, slug);
  const pages = handleLastViewedDelete(removedDuplicate);
  const parentTitle = getParentTitle(slug);

  return {
    ...pages,
    [slug]: {
      title: page.frontmatter.menu ?? page.frontmatter.title,
      subtitle: parentTitle,
      link: location.pathname,
      anchor: false,
    },
  };
};

export const handleLastViewedPages = (
  page: CmsPageType,
  setLastViewed: (value: LastViewedObject) => void,
) => {
  const lastViewedPages = getSavedPages();

  if (window.location.pathname === "/") {
    setLastViewed(lastViewedPages);
    return;
  }

  const result = addCurrent(lastViewedPages, page);

  localStorage.setItem("lastViewedPages", JSON.stringify(result));
};
