import { FunctionComponent, useRef, useState } from "react";
import { TextSections } from "../types/types";
import LinkBoxes from "./LinkBoxes";
import TabPanel from "./TabPanel";

interface Props {
  sections: TextSections[];
}

const Tabs: FunctionComponent<Props> = ({ sections }) => {
  const hash = location.hash ? +location.hash.replace("#tab", "") : undefined;
  const [activePanelId, setActivePanelId] = useState(hash);
  const tabPanelRef = useRef<HTMLDivElement>(null);
  const isIdUndefined = activePanelId !== undefined;
  const content = isIdUndefined && sections[activePanelId];

  return (
    <>
      <LinkBoxes
        items={sections}
        tabPanelRef={tabPanelRef}
        active={activePanelId}
        setActive={setActivePanelId}
      />
      {isIdUndefined && content && (
        <TabPanel content={content} tabPanelRef={tabPanelRef} />
      )}
    </>
  );
};

export default Tabs;
