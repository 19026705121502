import parse from "html-react-parser";
import { createContext, Dispatch } from "react";
import { getChapter, getChaptersOfPerson } from "./http/chapterApi";
import {
  Chapter,
  IamUser,
  UserManagmentItem,
  UserRight,
} from "./types/apiTypes";

export const rightsMapping = new Map<UserRight, string>([
  ["admin", "Admin"],
  ["manager", "Manager"],
  ["secretary", "Sekretär"],
  ["member", "Mitglied"],
]);

export const formatText = (text: string) => {
  const newText = text.split("\n").join("<br>");
  return parse(newText);
};

export const buildUserLabel = (item: IamUser) =>
  item.email ? `${item.name} (${item.email})` : item.id;

export const buildFunctionaryLabel = (item: UserManagmentItem) =>
  item.person &&
  `${item.person.firstName} ${item.person.lastName} (${item.person.email})`;

export const setTitle = (chapter: Chapter) => {
  document.title = `HGV Ortsgruppenportal | ${chapter.name.replace(
    "HGV Ortsgruppe ",
    "",
  )}`;
};

const getStorageChapter = () => {
  const chapterString = localStorage.getItem("chapter");
  const id = parseInt(chapterString ?? "");
  return Number.isNaN(id) ? undefined : id;
};

const setStorageChapterId = (id: number | undefined) => {
  const chapterIdJSON = JSON.stringify(id);
  chapterIdJSON && localStorage.setItem("chapter", chapterIdJSON);
};

const setStorageChapter = (chapter: Chapter | undefined) => {
  setStorageChapterId(chapter?.id);
};

export const loadChapter = async (personId: string | null | undefined) => {
  if (!personId) {
    const chapters = await getChapter();
    return chapters?.sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  const storageChapterId = getStorageChapter();
  const chapters = await getChaptersOfPerson(personId);

  setStorageChapterId(storageChapterId ?? chapters?.[0]?.id);

  return chapters;
};

export const getSelectionChapter = (chapters: Chapter[] | undefined) => {
  const storageChapterId = getStorageChapter();

  const storageChapter = chapters
    ?.filter((chapter) => chapter.id === storageChapterId)
    .shift();

  if (!storageChapter) {
    setStorageChapter(chapters?.[0]);
    return chapters?.[0];
  }

  return storageChapter;
};

export const NotificationContext = createContext<Dispatch<any>>(() => {});
