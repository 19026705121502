import { FunctionComponent } from "react";
import SVGOpen from "../../icons/Open.svg?react";
import { Newsletter } from "../../types/types";
import styles from "../ListItem.module.css";
import Icon from "../ui/Icon";
import Link from "../ui/Link";

interface Props {
  item: Newsletter;
}

const padTo2Digits = (num: number) => num.toString().padStart(2, "0");

const formatDate = (date: Date) =>
  [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join(".");

const NewsletterItem: FunctionComponent<Props> = ({ item }) => (
  <div className={styles.row}>
    <div>
      <p className={styles.label}>{item.title}</p>
    </div>
    <div>
      <p className={styles.label}>{formatDate(new Date(item.date))}</p>
    </div>
    <div>
      <Link newTab={true} url={item.link} className={styles["btn-download"]}>
        <Icon className={styles["btn-icon"]} glyph={SVGOpen} />
        <span className={styles["btn-label"]}>Lesen</span>
      </Link>
    </div>
  </div>
);

export default NewsletterItem;
