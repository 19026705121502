import { FunctionComponent } from "react";
import styles from "./MoreButton.module.css";
import Button from "./ui/Button";

interface Props {
  isVisible: boolean;
  onClick: () => void;
}

const MoreButton: FunctionComponent<Props> = ({ isVisible, onClick }) => (
  <>
    {isVisible && (
      <div className={styles["more-button"]}>
        <Button
          buttonProps={{
            onClick,
          }}
        >
          Mehr anzeigen
        </Button>
      </div>
    )}
  </>
);

export default MoreButton;
