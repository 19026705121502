import parse from "html-react-parser";
import { FunctionComponent } from "react";
import Footer from "./Footer";
import styles from "./Page.module.css";

interface Props {
  title: string;
  description?: string;
  children: React.ReactNode;
}

const Page: FunctionComponent<Props> = ({ title, description, children }) => (
  <main className={styles.page}>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles["header-top"]}>
          {title.length !== 0 && <h1 className={styles.title}>{title}</h1>}
        </div>
        {description && (
          <div className={styles.description}>{parse(description)}</div>
        )}
      </div>
      {children}
    </div>
    <Footer />
  </main>
);

export default Page;
