import classNames from "classnames";
import { FunctionComponent } from "react";
import { cmsUrl } from "../../http/cmsApi";
import { Material } from "../../types/types";
import { formatText } from "../../utils";
import Box from "../Box";
import styles from "../Boxes.module.css";

interface Props {
  items: Material[];
}

const MaterialBoxes: FunctionComponent<Props> = ({ items }) => (
  <div className={styles.boxes}>
    {items.map((item, index) => (
      <Box
        img={item.img}
        placeholder={false}
        url={`${cmsUrl}${location.pathname}`}
        key={index}
      >
        <div className={classNames(styles.content, styles["full-height"])}>
          <h3 className={styles.name}>{item.title}</h3>
          <p className={styles.text}>{item.description}</p>
          {item.text && (
            <p className={styles["full-text"]}>{formatText(item.text)}</p>
          )}
        </div>
      </Box>
    ))}
  </div>
);

export default MaterialBoxes;
