import { FunctionComponent } from "react";
import { LastViewedObject } from "../../types/types";
import Page from "../Page";
import useFetchPage from "../hooks/useFetchPage";
import LastViewed from "./LastViewed";

interface Props {
  lastViewed: LastViewedObject | undefined;
  setLastViewed: (value: LastViewedObject) => void;
}

const Home: FunctionComponent<Props> = ({ lastViewed, setLastViewed }) => {
  const page = useFetchPage(setLastViewed);

  return (
    <>
      {page && (
        <Page title={page.frontmatter.title} description={page.content}>
          <LastViewed items={lastViewed} />
        </Page>
      )}
    </>
  );
};

export default Home;
