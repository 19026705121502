import { FunctionComponent, useContext } from "react";
import { deleteUserPermissions } from "../../http/chapterApi";
import SVGDelete from "../../icons/Delete.svg?react";
import { UserManagmentItem, UserPermission } from "../../types/apiTypes";
import { NotificationContext } from "../../utils";
import Icon from "../ui/Icon";
import styles from "./UserManagmentList.module.css";

interface Props {
  item: UserManagmentItem;
  selectedItems: UserManagmentItem[];
  accountPermission: UserPermission;
  deleteUser: (user: UserManagmentItem) => void;
}

const UserManagmentDeleteButton: FunctionComponent<Props> = ({
  item,
  accountPermission,
  deleteUser,
  selectedItems,
}) => {
  const dispatch = useContext(NotificationContext);

  const handleDelete = () => {
    const isNew = selectedItems.filter((x) => x === item).length === 0;

    if (
      accountPermission.permission.toLowerCase() === "manager" &&
      item.permission.toLowerCase() === "admin"
    )
      return dispatch(
        "Löschen fehlgeschlagen: Sie sind zu dieser Aktion nicht berechtigt",
      );

    if (accountPermission.userId === item.iamUser?.id)
      return dispatch(
        "Löschen fehlgeschlagen: Sie können ihren eigenen Eintrag nicht löschen",
      );

    !isNew && item.iamUser
      ? deleteUserPermissions(item.iamUser.id)
          .then((response) => {
            if (response?.ok) return deleteUser(item);

            throw new Error("Löschen fehlgeschlagen");
          })
          .catch((error: Error) => {
            dispatch(error.message);
          })
      : deleteUser(item);
  };

  return (
    <button className={styles["clear-button"]} onClick={handleDelete}>
      <Icon glyph={SVGDelete} className={styles["clear-icon"]} />
    </button>
  );
};

export default UserManagmentDeleteButton;
