import { FunctionComponent } from "react";
import SVGPersonPlaceholder from "../icons/PersonPlaceholder.svg?react";
import styles from "./Box.module.css";

interface Props {
  img: Blob | string | undefined;
  placeholder?: boolean;
  url?: string;
  children: React.ReactNode;
}

const isBlob = (img: Blob | string): img is Blob =>
  (img as Blob).size !== undefined;

const Box: FunctionComponent<Props> = ({ img, placeholder, url, children }) => {
  const path = url ? `${url}/` : "";

  return (
    <div className={styles.box}>
      {img && (
        <div>
          <img
            className={styles.img}
            src={
              img && isBlob(img) ? URL.createObjectURL(img) : `${path}${img}`
            }
            loading="lazy"
          />
        </div>
      )}
      {!img && placeholder && (
        <div>
          <SVGPersonPlaceholder className={styles.img} />
        </div>
      )}
      {children}
    </div>
  );
};

export default Box;
