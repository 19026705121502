import classNames from "classnames";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { LastViewedPage, TextSections } from "../types/types";
import styles from "./LinkBox.module.css";
import Button from "./ui/Button";

interface Props {
  box: TextSections | LastViewedPage;
  onClick?: () => void;
  active?: boolean;
}

const isPage = (box: TextSections | LastViewedPage): box is LastViewedPage =>
  (box as LastViewedPage).link !== undefined;

const LinkBox: FunctionComponent<Props> = ({ box, onClick, active }) => {
  const navigate = useNavigate();

  const page = isPage(box);

  return (
    <article
      className={classNames(styles.box, { [styles["box-active"]]: active })}
      tabIndex={0}
    >
      {page && <span className={styles.parent}>{box.subtitle}</span>}
      <h3 className={styles.heading}>{box.title}</h3>
      <div className={styles.button}>
        <a
          tabIndex={-1}
          onClick={async (e) => {
            e.preventDefault();
            page && navigate(box.link);
          }}
        >
          <Button buttonProps={{ onClick }}>
            {page ? "Besuchen" : "Lesen"}
          </Button>
        </a>
      </div>
    </article>
  );
};

export default LinkBox;
