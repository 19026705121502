import { FunctionComponent } from "react";
import { UserPermission } from "../../types/apiTypes";
import Page from "../Page";
import UserManagmentList from "./UserManagmentList";

interface Props {
  accountPermission: UserPermission;
}

const UserManagment: FunctionComponent<Props> = ({ accountPermission }) => (
  <Page title="Benutzerverwaltung">
    <UserManagmentList accountPermission={accountPermission} />
  </Page>
);

export default UserManagment;
