import { Configuration, FrontendApi, Session } from "@ory/client-fetch";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface AuthContextType {
  session: Session | undefined;
  isNotAsked: boolean;
  logout: () => Promise<void>;
  resetPassword: () => Promise<void>;
  settingsUrl: string;
}

const AuthContext: React.Context<AuthContextType> =
  createContext<AuthContextType>(null!);

const basePath =
  import.meta.env.MODE === "production"
    ? "https://api.accounts.hgv.it"
    : "http://localhost:4000";

const ory = new FrontendApi(
  new Configuration({
    basePath,
    credentials: "include",
  }),
);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<Session>();
  const [isNotAsked, setNotAsked] = useState<boolean>(true);

  const logout = async () => {
    const flow = await ory.createBrowserLogoutFlow();
    await ory.updateLogoutFlow(
      {
        token: flow.logout_token,
      },
      {
        headers: {
          // Prevents ory from redirecting to a return_to url, which causes a fetch API CORS issue
          Accept: "application/json",
        },
      },
    );
    setSession(undefined);
  };

  const resetPassword = async () => {
    //TODO
  };

  useEffect(() => {
    ory
      .toSession()
      .then((data) => setSession(data))
      .finally(() => setNotAsked(false));
  }, []);

  const value: AuthContextType = {
    session,
    isNotAsked,
    logout,
    resetPassword,
    settingsUrl: `${basePath}/self-service/settings/browser`,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const isEmployee = (email: string) =>
  ["@hgv.it", "@hgj.it"].some((domain) => email.includes(domain));

export const useAuth = () => {
  return useContext(AuthContext);
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();

  if (!auth.session && !auth.isNotAsked) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they signin, which is a nicer user experience
    // than dropping them off on the home page.
    window.location.replace(
      `${basePath}/self-service/login/browser?return_to=${window.location.href}`,
    );
    return null;
  }

  return children;
};
