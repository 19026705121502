import { FunctionComponent } from "react";
import styles from "./ListItem.module.css";

interface Props {
  items: (string | undefined)[];
}

const ListHeader: FunctionComponent<Props> = ({ items }) => (
  <div className={styles.row}>
    {items.map((item, index) =>
      item ? (
        <div key={index}>
          <p className={styles.label}>
            <strong>{item}</strong>
          </p>
        </div>
      ) : (
        <div key={index}></div>
      ),
    )}
  </div>
);

export default ListHeader;
