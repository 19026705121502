import { FunctionComponent } from "react";
import SVGClear from "../../icons/Clear.svg?react";
import Icon from "../ui/Icon";
import styles from "./Modal.module.css";

interface Props {
  onClose: () => void;
  children: JSX.Element;
}

const Modal: FunctionComponent<Props> = ({ onClose, children }) => (
  <div className={styles.modal}>
    <div className={styles.overlay} onClick={onClose}></div>
    <div className={styles.content}>
      <button onClick={onClose} className={styles["clear-button"]}>
        <Icon glyph={SVGClear} className={styles["clear-icon"]} />
      </button>
      {children}
    </div>
  </div>
);

export default Modal;
