import { FunctionComponent, useEffect, useState } from "react";
import { SingleValue } from "react-select";
import { getIAMUsers } from "../../http/chapterApi";
import {
  ISelectOption,
  IamUser,
  UserManagmentItem,
} from "../../types/apiTypes";
import { buildUserLabel } from "../../utils";
import SelectBox from "../ui/SelectBox";

interface Props {
  item: UserManagmentItem;
  onChange: (value: IamUser) => void;
}

const UserSelect: FunctionComponent<Props> = ({ item, onChange }) => {
  const [iamUsers, setIAMUsers] = useState<IamUser[]>([]);

  useEffect(() => {
    loadIAMUsers();
  }, []);

  const loadIAMUsers = async (value?: string) => {
    const users = await getIAMUsers(value);
    users && setIAMUsers(users);
  };

  const options: ISelectOption[] = iamUsers.map((item) => ({
    value: item.id,
    label: buildUserLabel(item),
  }));

  return (
    <>
      {options && (
        <SelectBox
          value={
            item.iamUser
              ? {
                  label: buildUserLabel(item.iamUser),
                  value: item.iamUser.id,
                }
              : null
          }
          placeholder="Benutzer wählen"
          onChange={(value: SingleValue<ISelectOption>) => {
            const userInfo = iamUsers.filter((x) => x.id == value?.value)[0];
            userInfo && onChange(userInfo);
          }}
          onInputChange={loadIAMUsers}
          options={iamUsers.map((item) => ({
            value: item.id,
            label: buildUserLabel(item),
          }))}
        />
      )}
    </>
  );
};

export default UserSelect;
