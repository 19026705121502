import classNames from "classnames";
import { FunctionComponent, useRef } from "react";
import SVGClear from "../../icons/Clear.svg?react";
import SVGSearch from "../../icons/Search.svg?react";
import Icon from "./Icon";
import styles from "./SearchInput.module.css";

interface Props {
  inputValue: string;
  setInputValue: (value: string) => void;
  fullWidth?: boolean;
  inputObject?: any;
}

const SearchInput: FunctionComponent<Props> = ({
  inputValue,
  setInputValue,
  fullWidth = false,
  inputObject,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classNames(styles.search, {
        [styles["search-full-width"]]: fullWidth,
      })}
    >
      <input
        type="search"
        placeholder="Suche..."
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        className={styles["search-input"]}
        ref={inputRef}
        {...inputObject}
      />
      {inputValue ? (
        <Icon
          glyph={SVGClear}
          className={styles["clear-icon"]}
          onClick={() => {
            inputRef.current?.focus();
            setInputValue("");
          }}
        />
      ) : (
        <Icon glyph={SVGSearch} className={styles["search-icon"]} />
      )}
    </div>
  );
};

export default SearchInput;
