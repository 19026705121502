import { FunctionComponent } from "react";
import { NavItem } from "../types/types";
import MenuItem from "./MenuItem";

interface Props {
  items: NavItem[];
  active: string | undefined;
  setActive: (value: string | undefined) => void;
  setOpen: (value: boolean) => void;
}

const Menu: FunctionComponent<Props> = ({
  items,
  active,
  setActive,
  setOpen,
}) => (
  <>
    {items.map((item, index) => (
      <MenuItem
        item={item}
        key={index}
        index={index}
        onClick={() => {
          setActive(String(item.to));
          setOpen(false);
        }}
        active={active}
      />
    ))}
  </>
);

export default Menu;
