import { FunctionComponent, useEffect, useState } from "react";
import { Newsletter } from "../../types/types";
import ListHeader from "../ListHeader";
import MoreButton from "../MoreButton";
import NewsletterItem from "./Item";

interface Props {
  items: Newsletter[];
}

const NewsletterList: FunctionComponent<Props> = ({ items }) => {
  const [newsletters, setNewsletters] = useState<Newsletter[]>([]);
  const [pagination, setPagination] = useState(0);

  const multiplier = 10;

  useEffect(() => {
    items.length < multiplier
      ? setNewsletters(items)
      : setNewsletters(items.slice(0, pagination + multiplier));
  }, [items, pagination]);

  return (
    <>
      <ListHeader items={["TITEL", "DATUM", undefined]} />
      {!items.length ? (
        <p>Keine Newsletter vorhanden</p>
      ) : (
        newsletters.map((item) => (
          <NewsletterItem item={item} key={item.title} />
        ))
      )}
      <MoreButton
        isVisible={
          items.length > multiplier && pagination + multiplier < items.length
        }
        onClick={() => setPagination(pagination + multiplier)}
      />
    </>
  );
};

export default NewsletterList;
