export const registerErrorReporting = () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!Object.fromEntries) {
    return;
  }

  let isSentryLoaded = false;

  const onFirstError = async (event: ErrorEvent) => {
    const Sentry = await import("@sentry/browser");
    !isSentryLoaded &&
      Sentry.init({
        dsn: "https://1375c495362fb4891c01c3ecb3cacaa2@o4508256406208512.ingest.de.sentry.io/4508342101082192",
        autoSessionTracking: false,
      });

    isSentryLoaded = true;
    window.removeEventListener("error", onFirstError);

    Sentry.captureException(event.error);
  };

  window.addEventListener("error", onFirstError);
};
