import classNames from "classnames";
import { FunctionComponent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import SVGAccountTree from "../icons/AccountTree.svg?react";
import SVGCalendar from "../icons/Calendar.svg?react";
import SVGCelebration from "../icons/Celebration.svg?react";
import SVGDeck from "../icons/Deck.svg?react";
import SVGDescription from "../icons/Description.svg?react";
import SVGGroup from "../icons/Group.svg?react";
import SVGHgv from "../icons/Hgv.svg?react";
import SVGLetter from "../icons/Letter.svg?react";
import SVGList from "../icons/List.svg?react";
import SVGPeople from "../icons/People.svg?react";
import SVGSend from "../icons/Send.svg?react";
import SVGTask from "../icons/Task.svg?react";
import { NavItem, NavItemGroup } from "../types/types";
import { DataContext } from "./AppRouter";
import styles from "./Menu.module.css";
import MenuItems from "./MenuItems";
import Icon from "./ui/Icon";

export const navItemsGroup: NavItemGroup[] = [
  {
    text: "Ratgeber",
    items: [
      {
        icon: <Icon glyph={SVGTask} />,
        text: "Grundlagen",
        to: "/grundlagen",
      },
      {
        icon: <Icon glyph={SVGList} />,
        text: "Anleitung für Vereine",
        to: "/anleitung-fuer-vereine",
      },
      {
        icon: <Icon glyph={SVGCelebration} />,
        text: "Aktivitäten",
        to: "/aktivitaeten",
      },
    ],
  },
  {
    text: "Vorlagen & Materialien",
    items: [
      {
        icon: <Icon glyph={SVGDescription} />,
        text: "Vorlagen",
        to: "/vorlagen",
      },
      {
        icon: <Icon glyph={SVGHgv} />,
        text: "Logo",
        to: "/logo",
      },
      {
        icon: <Icon glyph={SVGDeck} />,
        text: "Materialien",
        to: "/materialien",
      },
    ],
  },
  {
    text: "Mitglieder & Funktionäre",
    items: [
      {
        icon: <Icon glyph={SVGPeople} />,
        text: "Ausschussmitglieder",
        to: "/ausschussmitglieder",
      },
      {
        icon: <Icon glyph={SVGGroup} />,
        text: "Mitglieder",
        to: "/mitglieder",
      },
    ],
  },
  {
    text: "Admin Bereich",
    items: [
      {
        icon: <Icon glyph={SVGPeople} />,
        text: "Benutzerverwaltung",
        to: "/benutzerverwaltung",
      },
    ],
    admin: true,
  },
];

const navItems: NavItem[] = [
  {
    icon: <Icon glyph={SVGSend} />,
    text: "HGV Kontakte",
    to: "/kontakte",
  },
  {
    icon: <Icon glyph={SVGLetter} />,
    text: "Newsletter",
    to: "/newsletter",
  },
  {
    icon: <Icon glyph={SVGCalendar} />,
    text: "Terminkalender",
    to: "/terminkalender",
  },
  {
    icon: <Icon glyph={SVGAccountTree} />,
    text: "Organigramm",
    to: "/organigramm",
  },
];

interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

const Menu: FunctionComponent<Props> = ({ isOpen, setOpen }) => {
  const [active, setActive] = useState<string | undefined>(location.pathname);
  const { permissions } = useContext(DataContext);

  const userManagmentAllowed =
    permissions?.permission === "admin" ||
    permissions?.permission === "manager";

  return (
    <aside
      className={classNames(styles.menu, {
        [styles["menu-closed"]]: !isOpen,
      })}
    >
      <div className={styles["scrollable-content"]}>
        <div className={styles.logo}>
          <div className={styles["logo-inner"]}>
            <Link
              to="/"
              className={styles["logo-text"]}
              onClick={() => {
                setActive(undefined);
                setOpen(false);
              }}
            >
              <Icon glyph={SVGHgv} className={styles["logo-icon"]} />
              ortsgruppenportal
            </Link>
          </div>
        </div>
        <nav className={styles.nav}>
          <ul>
            <div className={styles["menu-margin"]}>
              <MenuItems
                items={navItems}
                active={active}
                setActive={setActive}
                setOpen={setOpen}
              />
            </div>
            {navItemsGroup.map(({ text, items, admin }, i) => {
              if (admin && !userManagmentAllowed) return;
              return (
                <li key={i} className={styles["menu-group"]}>
                  <span className={styles["menu-group-title"]}>{text}</span>
                  <ul>
                    <MenuItems
                      items={items}
                      active={active}
                      setActive={setActive}
                      setOpen={setOpen}
                    />
                  </ul>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Menu;
