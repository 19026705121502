import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Chapter } from "../types/apiTypes";
import { getSelectionChapter, loadChapter, setTitle } from "../utils";
import { DataContext } from "./AppRouter";
import ChapterSelection from "./ChapterSelection";
import styles from "./ChapterSelection.module.css";

const ChapterField: FunctionComponent = () => {
  const { permissions, chapter, setChapter } = useContext(DataContext);
  const [chapterList, setChapterList] = useState<Chapter[]>();

  const isMember = permissions?.permission === "member";
  isMember && chapter && setTitle(chapter);

  useEffect(() => {
    (async () => {
      const chapters = await loadChapter(permissions?.personId);

      setChapterList(chapters);
      setChapter(getSelectionChapter(chapters) ?? chapters?.[0]);
    })();
  }, []);

  return (chapterList?.length ?? 0) > 1 ? (
    <ChapterSelection chapterList={chapterList} />
  ) : (
    <div className={styles.search}>
      <p className={styles.label}>HGV Ortsgruppe:</p>
      <p className={styles["chapter-value"]}>
        <strong>{chapter?.name}</strong>
      </p>
    </div>
  );
};

export default ChapterField;
