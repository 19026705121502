import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";
import "react-day-picker/dist/style.css";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { registerErrorReporting } from "./errorReporting";
import "./global.css";

registerErrorReporting();

const rootEl = document.querySelector("#root");
const root = rootEl && createRoot(rootEl);

root?.render(<App />);
