import { FunctionComponent, createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { getPermissions } from "../http/chapterApi";
import { Chapter, UserPermission } from "../types/apiTypes";
import { LastViewedObject } from "../types/types";
import styles from "./AppRouter.module.css";
import Header from "./Header";
import NotFound from "./NotFound";
import Activities from "./activities/Activities";
import Basics from "./basics/Basics";
import Calendar from "./calendar/Calendar";
import Committee from "./committee/Committee";
import Contact from "./contact/Contact";
import Guide from "./guide/Guide";
import Home from "./home/Home";
import Logo from "./logo/Logo";
import Materials from "./materials/Materials";
import Member from "./member/Member";
import Newsletter from "./newsletter/Newsletter";
import NotAuthorized from "./notAuthorized/NotAuthorized";
import Organigram from "./organization/OrganizationChart";
import Templates from "./templates/Templates";
import Loading from "./ui/Loading";
import UserManagment from "./userManagment/UserManagment";

interface Data {
  chapter: Chapter | undefined;
  permissions: UserPermission | null | undefined;
  setChapter: (value: Chapter | undefined) => void;
}

export const DataContext = createContext<Data>({
  chapter: undefined,
  permissions: undefined,
  setChapter: () => {},
});

const AppRouter: FunctionComponent = () => {
  const [lastViewed, setLastViewed] = useState<LastViewedObject>();
  const [chapter, setChapter] = useState<Chapter>();
  const [permissions, setPermissions] = useState<UserPermission | null>();
  const account = useAuth();

  useEffect(() => {
    (async () => {
      account.session?.identity?.id && setPermissions(await getPermissions());
    })();
  }, [account.session]);

  return permissions === undefined ? (
    <Loading fullHeight={true} />
  ) : !permissions ? (
    <NotAuthorized />
  ) : (
    <DataContext.Provider
      value={{
        chapter: chapter,
        permissions: permissions,
        setChapter: setChapter,
      }}
    >
      <Header />
      <div className={styles.app}>
        <Routes>
          <Route
            path="/kontakte"
            element={<Contact setLastViewed={setLastViewed} />}
          />
          <Route
            path="/newsletter"
            element={<Newsletter setLastViewed={setLastViewed} />}
          />
          <Route
            path="/terminkalender"
            element={<Calendar setLastViewed={setLastViewed} />}
          />
          <Route
            path="/organigramm"
            element={<Organigram setLastViewed={setLastViewed} />}
          />
          {permissions.permission !== "member" &&
            permissions.permission !== "secretary" && (
              <Route
                path="/benutzerverwaltung"
                element={<UserManagment accountPermission={permissions} />}
              />
            )}
          <Route
            path="/mitglieder"
            element={<Member setLastViewed={setLastViewed} />}
          />
          <Route
            path="/ausschussmitglieder"
            element={<Committee setLastViewed={setLastViewed} />}
          />
          <Route
            path="/materialien"
            element={<Materials setLastViewed={setLastViewed} />}
          />
          <Route
            path="/logo"
            element={<Logo setLastViewed={setLastViewed} />}
          />
          <Route
            path="/vorlagen"
            element={<Templates setLastViewed={setLastViewed} />}
          />
          <Route
            path="/aktivitaeten"
            element={<Activities setLastViewed={setLastViewed} />}
          />
          <Route
            path="/anleitung-fuer-vereine"
            element={<Guide setLastViewed={setLastViewed} />}
          />
          <Route
            path="/grundlagen"
            element={<Basics setLastViewed={setLastViewed} />}
          />
          <Route
            path="/"
            element={
              <Home setLastViewed={setLastViewed} lastViewed={lastViewed} />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </DataContext.Provider>
  );
};

export default AppRouter;
