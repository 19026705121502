import classNames from "classnames";
import { format } from "phone-fns";
import { FunctionComponent, useEffect, useState } from "react";
import { getPersonImage } from "../http/chapterApi";
import { Person } from "../types/apiTypes";
import Box from "./Box";
import styles from "./Boxes.module.css";

interface Props {
  item: Person;
  showImage: boolean;
}

const PersonBox: FunctionComponent<Props> = ({ item, showImage }) => {
  const [image, setImage] = useState<Blob>();

  useEffect(() => {
    item.picture && getImage();
  }, [item.picture]);

  const getImage = async () => {
    setImage(item.id ? await getPersonImage(item.id) : undefined);
  };

  return (
    <Box
      img={showImage ? image : undefined}
      placeholder={showImage}
      key={item.id}
    >
      <div className={styles.content}>
        <h3 className={styles.name}>
          {item.name ?? ""} {item.lastName ?? ""}
        </h3>
        <p className={classNames(styles.company, styles.text)}>
          {item.companyName ?? ""}
        </p>
        <div className={styles.roles}>
          {item.roles?.map((role) => (
            <p key={role} className={styles.text}>
              {role}
            </p>
          ))}
        </div>
        <div className={styles.address}>
          <p className={styles.text}>{item.street}</p>
          <p className={styles.text}>
            {item.zip} {item.city}
          </p>
        </div>
        {item.phone && (
          <p className={styles.text}>
            <a
              href={`tel:+39${item.phone.replace(/\s+/g, "")}`}
              className={styles.phone}
            >
              +39 {format("NNNN NNNNNN", item.phone)}
            </a>
          </p>
        )}
        {item.mobile && (
          <p className={styles.text}>
            <a
              href={`tel:+39${item.mobile.replace(/\s+/g, "")}`}
              className={styles.phone}
            >
              +{format("NN NNN NNNNNNN", item.mobile).replace("N", "")}
            </a>
          </p>
        )}
        {item.email && (
          <p className={styles.text}>
            <a href={`mailto:${item.email}`} className={styles.email}>
              {item.email}
            </a>
          </p>
        )}
        {item.website && (
          <p className={styles.text}>
            <a
              href={`https://${item.website}`}
              className={styles.email}
              target="_blank"
              rel="noreferrer"
            >
              {item.website}
            </a>
          </p>
        )}
      </div>
    </Box>
  );
};

export default PersonBox;
