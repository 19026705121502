import { CmsPageType } from "../types/types";

export const cmsUrl =
  import.meta.env.MODE === "production"
    ? "https://cms.ortsgruppen.hgv.it"
    : "http://localhost:6872";

export const getPage = async () => {
  const slug = location.pathname;
  const fetchedData = await fetch(
    `${cmsUrl}${slug !== "/" ? `${slug}.json` : ""}`,
  );
  if (fetchedData.status !== 200) throw new Error("Fetch failed!");
  const data: CmsPageType = await fetchedData.json();

  return data;
};
