import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { Glyph } from "../../types/types";
import styles from "./Button.module.css";
import Icon from "./Icon";

interface Props {
  glyph?: Glyph;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  activeColor?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
}

const Button: FunctionComponent<Props> = ({
  glyph,
  buttonProps = {},
  activeColor = false,
  fullWidth = false,
  children,
}) => (
  <button
    {...buttonProps}
    className={classNames(styles.btn, buttonProps.className, {
      [styles.active]: activeColor,
      [styles.fullWidth]: fullWidth,
    })}
  >
    {glyph && <Icon className={styles["btn-icon"]} glyph={glyph} />}
    {children}
  </button>
);

export default Button;
