import { FunctionComponent } from "react";
import Select, { SingleValue, StylesConfig } from "react-select";
import { ISelectOption } from "../../types/apiTypes";
import styles from "./SelectBox.module.css";

interface Props {
  value?: ISelectOption | null;
  options: ISelectOption[];
  placeholder: string;
  onChange: (value: SingleValue<ISelectOption>) => void;
  onInputChange?: (value: string) => void;
}

const SelectBox: FunctionComponent<Props> = ({
  value,
  options,
  placeholder,
  onChange,
  onInputChange,
}) => {
  const selectStyle: StylesConfig<ISelectOption | undefined, false> = {
    control: (provided) => ({
      ...provided,
      color: "white",
      boxShadow: "3px 3px 5px rgb(0 0 0 / 2%)",
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  return (
    <Select
      placeholder={placeholder}
      onChange={(value) => {
        // Leave code like this, with the shortcut this code throws an error
        value && onChange(value);
      }}
      onInputChange={(value) => {
        // Leave code like this, with the shortcut this code throws an error
        onInputChange?.(value);
      }}
      className={styles.select}
      isOptionDisabled={(option) => option?.isDisabled ?? false}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#f7f7f7",
          primary50: "#f7f7f7",
          primary75: "#f7f7f7",
          primary: "#494d50",
        },
      })}
      styles={selectStyle}
      options={options}
      value={value}
    />
  );
};

export default SelectBox;
