import { FunctionComponent } from "react";
import { Person } from "../types/apiTypes";
import styles from "./Boxes.module.css";
import PersonBox from "./PersonBox";

interface Props {
  items: Person[];
  showImage: boolean;
}

const PersonBoxes: FunctionComponent<Props> = ({ items, showImage }) => (
  <div className={styles.boxes}>
    {items.map((item, index) => (
      <PersonBox item={item} showImage={showImage} key={index} />
    ))}
  </div>
);

export default PersonBoxes;
