import { Identity } from "@ory/client-fetch";

export interface User {
  email: string;
  name?: string;
  profilePhoto?: string | undefined;
}

export interface UserIdentity extends Identity {
  traits: {
    email: string;
    name: string;
  };
  metadata_public?: {
    profile_photo?: string;
  };
}

export const isUserIdentity = (i: Identity): i is UserIdentity => {
  return [
    "e5cb916994e485d758d041541f12385b37d0f93967d612e0937f60b7b1566012c6ab13379e79a9b4b58fe41f1e675ee80d9e38a2f1660ce67ce981ac3db2a033",
  ].includes(i.schema_id);
};

export interface EmailIdentity extends Identity {
  traits: {
    email: string;
  };
}

export const isEmailIdentity = (i: Identity): i is EmailIdentity => {
  return i.schema_id === "preset://email";
};
