import { FunctionComponent, useEffect, useState } from "react";
import { getUsers } from "../../http/chapterApi";
import {
  UserManagmentItem,
  UserPermission,
  userRights,
} from "../../types/apiTypes";
import { NotificationContext } from "../../utils";
import MoreButton from "../MoreButton";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import SearchInput from "../ui/SearchInput";
import NewUsers from "./NewUsers";
import SelectedUsers from "./SelectedUsers";
import styles from "./UserManagmentList.module.css";

interface Props {
  accountPermission: UserPermission;
}

const searchByValue = (value: string, selectedUsers: UserManagmentItem[]) =>
  selectedUsers
    .filter(
      (item) =>
        item.iamUser?.name?.toLowerCase().includes(value.toLowerCase()) ||
        item.person?.firstName?.toLowerCase().includes(value.toLowerCase()) ||
        item.person?.lastName?.toLowerCase().includes(value.toLowerCase()),
    )
    .sort((a, b) =>
      userRights.indexOf(a.permission) > userRights.indexOf(b.permission)
        ? -1
        : 1,
    );

const UserManagmentList: FunctionComponent<Props> = ({ accountPermission }) => {
  const [fetchedUsers, setFetchedUsers] = useState<UserManagmentItem[]>([]);
  const [newUsers, setNewUsers] = useState<UserManagmentItem[]>([]);
  const [currentUsers, setCurrentUsers] = useState<UserManagmentItem[]>([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const users = await getUsers();
    users && setFetchedUsers(users);
    users && setCurrentUsers(searchByValue(inputValue, users));
    setIsLoading(false);
  };

  const multiplier = 20;

  const [inputValue, setInputValue] = useState("");
  const [error, dispatch] = useState<string>();
  const [pagination, setPagination] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setCurrentUsers(searchByValue(inputValue, fetchedUsers));
  }, [inputValue]);

  return isLoading ? (
    <Loading />
  ) : (
    <NotificationContext.Provider value={dispatch}>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles["search-row"]}>
        <SearchInput inputValue={inputValue} setInputValue={setInputValue} />
        <Button
          buttonProps={{
            onClick: () => {
              setNewUsers([
                ...newUsers,
                {
                  iamUser: null,
                  permission: "member",
                  person: null,
                },
              ]);
            },
          }}
        >
          Benutzer hinzufügen
        </Button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div>
            <strong>Login Benutzer</strong>
          </div>
          <div>
            <strong>Funktionär</strong>
          </div>
          <div>
            <strong>Rolle</strong>
          </div>
        </div>
        <NewUsers
          newUsers={newUsers}
          setNewUsers={setNewUsers}
          selectedUsers={fetchedUsers}
          accountPermission={accountPermission}
          loadUsers={loadUsers}
        />
        <SelectedUsers
          items={
            currentUsers.length < multiplier
              ? currentUsers
              : currentUsers.slice(0, pagination + multiplier)
          }
          accountPermission={accountPermission}
          updateUsers={setCurrentUsers}
          loadUsers={loadUsers}
        />
      </div>
      <MoreButton
        isVisible={
          currentUsers.length > multiplier &&
          pagination + multiplier < currentUsers.length
        }
        onClick={() => setPagination(pagination + multiplier)}
      />
    </NotificationContext.Provider>
  );
};

export default UserManagmentList;
