import Page from "./Page";

const NotFound = () => {
  return (
    <Page title="Fehler: 404">
      <p>Die Seite wurde nicht gefunden</p>
    </Page>
  );
};

export default NotFound;
