import { useEffect, useState } from "react";
import { handleLastViewedPages } from "../../handleLastViewed";
import { getPage } from "../../http/cmsApi";
import { CmsPageType, LastViewedObject } from "../../types/types";

const useFetchPage = (setLastViewed: (value: LastViewedObject) => void) => {
  const [page, setPage] = useState<CmsPageType>();

  useEffect(() => {
    const getData = async () => {
      setPage(await getPage());
    };
    getData();
  }, []);

  useEffect(() => {
    page && handleLastViewedPages(page, setLastViewed);
  }, [page]);

  return page;
};

export default useFetchPage;
