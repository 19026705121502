import classNames from "classnames";
import { FunctionComponent, memo, MouseEventHandler } from "react";
import { Glyph } from "../../types/types";
import styles from "./Icon.module.css";

interface Props {
  glyph: Glyph;
  className?: string;
  onClick?: MouseEventHandler;
}

const Icon: FunctionComponent<Props> = ({
  glyph: Glyph,
  className,
  onClick,
}) => (
  <Glyph className={classNames(styles.icon, className)} onClick={onClick} />
);

export default memo(Icon);
