import classNames from "classnames";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { NavItem } from "../types/types";
import styles from "./MenuItem.module.css";

interface Props {
  item: NavItem;
  index: number;
  active: string | undefined;
  onClick: () => void;
}

const MenuItem: FunctionComponent<Props> = ({
  item,
  index,
  active,
  onClick,
}) => (
  <li key={index} className={styles["menu-item"]}>
    <Link
      to={item.to}
      onClick={onClick}
      className={classNames(styles["menu-link"], {
        [styles["menu-active"]]: String(item.to) === active,
      })}
    >
      {item.icon} {item.text}
    </Link>
  </li>
);

export default MenuItem;
