import { FunctionComponent } from "react";
import { LastViewedObject } from "../../types/types";
import LinkBoxes from "../LinkBoxes";
import styles from "./LastViewed.module.css";

interface Props {
  items: LastViewedObject | undefined;
}

const LastViewed: FunctionComponent<Props> = ({ items }) => (
  <>
    {items && (
      <div>
        <h2 className={styles.title}>Ihre zuletzt besuchten Seiten</h2>
        {Object.keys(items).length !== 0 ? (
          <LinkBoxes items={Object.values(items).reverse()} />
        ) : (
          <div className={styles.status}>
            Es wurden keine zuletzt besuchten Seiten gefunden.
          </div>
        )}
      </div>
    )}
  </>
);

export default LastViewed;
