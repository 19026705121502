export type UserRight = (typeof userRights)[number];
export const userRights = ["admin", "manager", "secretary", "member"] as const;

interface FunctionaryPerson {
  firstName: string;
  id: string;
  lastName: string;
  city: string;
  email: string;
  phone: string;
  mobile: string;
  picture: string;
  street: string;
  zip: string;
}

export interface Person {
  id: string | null;
  picture: string | null;
  name: string | null;
  lastName: string | null;
  email: string;
  phone?: string | undefined;
  mobile?: string | undefined;
  street: string;
  zip: string;
  city: string;
  companyName: string | null;
  roles: string[] | null;
  website?: string | undefined;
}

export interface ChapterFunctionary {
  id: string;
  company: string;
  person: FunctionaryPerson;
  isChapterChairman: boolean;
  isChapterChairmanDeputy: boolean;
  roles: string[];
}

export interface IamUser {
  id: string;
  name: string | null;
  email: string | null;
}

export interface Functionary {
  personId: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface Committee {
  id: number;
  person: FunctionaryPerson;
  area: {
    id: number;
    name: string;
  };
  district: {
    id: number;
    name: string;
  };
}
export interface OrganizationType {
  president: { person: FunctionaryPerson } | undefined;
  countryCommittee: { person: FunctionaryPerson }[];
  districtCommittee: Committee[];
  areaCommittee: Committee[];
  chapters: number;
  members: number;
}

export interface UserManagmentItem {
  iamUser: IamUser | null;
  person: Functionary | null;
  permission: UserRight;
}

export interface UserPermission {
  userId: string;
  personId: string | null;
  permission: UserRight;
}

export interface ISelectOption {
  value: string | null;
  label: string;
  isDisabled?: boolean;
}

export interface Member {
  city: string;
  company: string;
  email: string;
  legalRepresentative: { firstName: string; lastName: string };
  phone?: string;
  mobile?: string;
  street: string;
  zip: string;
  website?: string;
}

export interface Chapter {
  id: number;
  name: string;
  taxCode: string | null;
  vatNumber: string | null;
  area: {
    id: number;
    name: string;
    district: {
      id: number;
      name: string;
    };
  };
}

export interface DistrictMember {
  id: number;
  person: FunctionaryPerson;
  company: string;
  roles: string[];
  isChapterChairman: boolean;
  isChapterChairmanDeputy: boolean;
  isPresident: boolean;
  isDirector: boolean;
  isDistrictChairman: boolean;
  isAreaChairman: boolean;
  isSuedtirolerGastwirtinnenDistrictRepresentative: boolean;
}

export interface EventType {
  id: number;
  title: string;
  description: string | null;
  location: string | null;
  from: string;
  to: string;
  category: string;
  signUpLink: string | null;
}

export interface ModalType {
  isOpen: boolean;
  data: EventType[] | undefined;
}
