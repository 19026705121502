import { FunctionComponent } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider, RequireAuth } from "../../context/authContext";
import AppRouter from "./AppRouter";

const App: FunctionComponent = () => (
  <BrowserRouter>
    <AuthProvider>
      <RequireAuth>
        <AppRouter />
      </RequireAuth>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
