import { FunctionComponent, RefObject } from "react";
import { LastViewedPage, TextSections } from "../types/types";
import LinkBox from "./LinkBox";
import styles from "./LinkBoxes.module.css";

interface Props {
  items: TextSections[] | LastViewedPage[];
  tabPanelRef?: RefObject<HTMLDivElement>;
  active?: number | undefined;
  setActive?: (value: number) => void;
  setExpand?: (value: boolean) => void;
}

const LinkBoxes: FunctionComponent<Props> = ({
  items,
  tabPanelRef,
  active,
  setActive,
  setExpand,
}) => (
  <div className={styles.boxes}>
    {items.map((box, index) => (
      <LinkBox
        key={index}
        box={box}
        onClick={() => {
          setActive?.(index);
          setExpand?.(false);
          location.hash = `tab${index}`;
          setTimeout(() => {
            tabPanelRef?.current?.scrollIntoView();
          }, 50);
        }}
        active={active === index}
      />
    ))}
  </div>
);

export default LinkBoxes;
