import classNames from "classnames";
import { FunctionComponent } from "react";
import { UserManagmentItem, UserPermission } from "../../types/apiTypes";
import {
  buildFunctionaryLabel,
  buildUserLabel,
  rightsMapping,
} from "../../utils";
import styles2 from "../ui/SelectBox.module.css";
import UserManagmentDeleteButton from "./UserManagmentDeleteButton";
import styles from "./UserManagmentList.module.css";

interface Props {
  items: UserManagmentItem[];
  accountPermission: UserPermission;
  updateUsers: (users: UserManagmentItem[]) => void;
  loadUsers: () => void;
}

const SelectedUsers: FunctionComponent<Props> = ({
  items,
  accountPermission,
  updateUsers,
  loadUsers,
}) => (
  <>
    {!items.length ? (
      <div>Keine Ergebnisse</div>
    ) : (
      items.map((item, index) => (
        <div
          className={classNames(styles.row, {
            [styles["row-error"]]: !item.iamUser?.email,
          })}
          key={`selected_users_${index}`}
        >
          <p className={styles2.field}>
            {item.iamUser && buildUserLabel(item.iamUser)}
          </p>
          <p className={styles2.field}>
            {buildFunctionaryLabel(item) ?? "Kein Funktionär"}
          </p>
          <p className={styles2.field}>{rightsMapping.get(item.permission)}</p>
          <UserManagmentDeleteButton
            item={item}
            selectedItems={items}
            accountPermission={accountPermission}
            deleteUser={(user) => {
              updateUsers(items.filter((x) => x !== user));
              loadUsers();
            }}
          />
        </div>
      ))
    )}
  </>
);

export default SelectedUsers;
