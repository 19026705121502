import classNames from "classnames";
import { FunctionComponent } from "react";
import styles from "./Loading.module.css";
import LoadingSpinner from "./LoadingSpinner";

interface Props {
  fullHeight?: boolean;
}

const Loading: FunctionComponent<Props> = ({ fullHeight = false }) => (
  <div
    className={classNames(styles.loading, {
      [styles["loading-full-height"]]: fullHeight,
    })}
  >
    <LoadingSpinner />
  </div>
);

export default Loading;
