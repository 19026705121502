import { FunctionComponent } from "react";
import { LastViewedObject } from "../../types/types";
import Page from "../Page";
import useFetchPage from "../hooks/useFetchPage";
import Boxes from "./Boxes";

interface Props {
  setLastViewed: (value: LastViewedObject) => void;
}

const Materials: FunctionComponent<Props> = ({ setLastViewed }) => {
  const page = useFetchPage(setLastViewed);

  return (
    <>
      {page && (
        <Page title={page.frontmatter.title} description={page.content}>
          {page.frontmatter.material && (
            <Boxes items={page.frontmatter.material} />
          )}
        </Page>
      )}
    </>
  );
};

export default Materials;
